import React from "react";

export default function Loader() {
  return (
    <>
      <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
        <img src="/logo.png" alt="Loader" class="flip-vertical-right" />
        {/* Loading... */}
      </div>
    </>
  );
}
