import React, { useEffect } from "react";
import Header from "./components/Header";

import { Link, useLocation, useNavigate } from "react-router-dom";
import SvgComponent from "./components/SvgComponent";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../service/cricket";
import moment from "moment";
import MatkaDashboard from "./matka/MatkaDashboard";
import { getAllSoccerMatchListAPI } from "../service/soccer&tennis";
import Loader from "./components/Loader";
import Slider from "react-slick";

const Matches = ({ isUpcoming }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedGame, setSelectedGame] = React.useState(
    location.pathname.includes("casino") ? "casino" : "cricket"
  );
  const [loading, setLoading] = React.useState(false);

  const userData = useSelector((state) => state.account.userData);
  const [selectedFilter, setSelectedFilter] = React.useState("today");
  const [filters, setFilters] = React.useState([
    { name: "Today", value: "today" },
    { name: "Tomorrow", value: "tomorrow" },
    { name: "Upcoming", value: "upcoming" },
  ]);
  const cricketData = useSelector((state) =>
    state?.gameData?.cricketGames
      ?.filter((item) => {
        if (isUpcoming) {
          return !item?.inPlay;
        } else {
          return true;
        }
      })
      .map((item) => {
        return {
          ...item,
          sport: "cricket",
        };
      })
  );

  const naviagte = useNavigate();

  const getCricketMatches = async () => {
    try {
      const { response, code } = await getAllMatchesOddsCricketAPI();

      if (code == 200) {
        let counts = await getBetCount();
        response.map((item) => {
          let countData =
            counts?.betCount[
              Object.keys(counts.betCount).find(
                (count) => count == item?.cricketId
              )
            ];

          item.matchCount = countData?.matchCount;
          item.fancyCount = countData?.fancyCount;
          return item;
        });

        dispatch({
          type: "gameData/setCricketGames",
          payload: response,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setLoading(true);
    let interval;
    getCricketMatches();
    if (selectedGame == "cricket") {
      getCricketMatches();
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    }
    setLoading(false);
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
    }
  };

  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
    slidesToShow: 1,
  };
  const IMAGES = [
    "https://opt-v3-files.raksahb.com/banner/31/TurboGamesMobileYoloEng.png",
    "https://opt-v3-files.raksahb.com/banner/31/banner400bonusYoloMobEng.png",
  ];
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="position-relative">
        <main className="warp">
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <Slider
              arrows={false}
              slidesPerRow={1}
              slidesToScroll={1}
              nextArrow={false}
              adaptiveHeight={false}
              autoplay={true}
              autoplaySpeed={3000}
              infinite={true}
            >
              {IMAGES?.map((item) => (
                <div>
                  <img
                    style={{
                      height: "120px",
                    }}
                    src={item}
                    class=""
                    alt="..."
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="sc-bYwvMP gQQySU">
            {filters?.map((item) => (
              <div
                onClick={() => setSelectedFilter(item.value)}
                className={`sc-jKmXuR ${
                  selectedFilter == item.value ? "kitwBU" : "ipverp"
                }`}
              >
                <p>{item.name}</p>
              </div>
            ))}
          </div>

          <ul className="games-types-top">
            <li
              style={{ width: "50%", zIndex: 100 }}
              onClick={() => setSelectedGame("cricket")}
              className={`cricket-button ${
                selectedGame == "cricket" ? "active" : ""
              }`}
            >
              <i class="far fa-cricket fa-fw" />
              <span>Cricket</span>
            </li>
            <li
              style={{ width: "50%", zIndex: 100 }}
              onClick={() => setSelectedGame("casino")}
              className={`Casino ${selectedGame == "casino" ? "active" : ""}`}
            >
              <i class="fas fa-spade" />
              <span>Casino</span>
            </li>

            {/*  <li
              onClick={() => setSelectedGame("matka")}
              className={`Matka ${selectedGame == "matka" ? "active" : ""}`}
            >
              <i class="fas fa-cauldron" />
              <span>Matka</span>
            </li> */}
          </ul>

          {selectedGame == "cricket" ||
          selectedGame === "soccer" ||
          selectedGame == "tennis" ? (
            <>
              <div
                style={{
                  marginTop: "10px",
                }}
                className="sc-feryYK bZXZnm"
              >
                <div className="sc-cLQEGU ifCcgI">
                  <div className="sc-hMFtBS ihIKhX">In-Play</div>
                </div>
              </div>
              {/* <div>
                {cricketData
                  ?.filter((item) => item?.inPlay)
                  ?.map((gameEvent) => (
                    <div
                      className={`old-matches-list ${
                        isUpcoming ? "" : "live-match"
                      }`}
                    >
                      <div className="list-matches">
                        <div onClick={() => navigateToGame(gameEvent)}>
                          <h6>
                            {selectedGame == "cricket" ? (
                              <i class="far fa-cricket fa-fw blink" />
                            ) : selectedGame == "soccer" ? (
                              <i class="far fa-futbol fa-fw blink" />
                            ) : selectedGame == "tennis" ? (
                              <i class="far fa-tennis-ball fa-fw blink" />
                            ) : null}
                            <span className="d-inline-flex align-items-center float-left mx-2">
                              <div className="blink" />
                            </span>
                            {gameEvent.eventName}
                          </h6>
                          <h5>
                            {moment(
                              gameEvent.eventTime
                                ? gameEvent.eventTime
                                : gameEvent.eventDate
                            ).format("DD MMM hh:mm A")}
                          </h5>
                          <div className="match-info">
                            <p>
                              Match Bets :{" "}
                              {gameEvent?.matchCount
                                ? gameEvent?.matchCount
                                : 0}
                            </p>
                            <p>
                              Session Bets :{" "}
                              {gameEvent?.fancyCount
                                ? gameEvent?.fancyCount
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div> */}
              {cricketData
                ?.filter((item) => item?.inPlay)
                ?.map((gameEvent) => (
                  <div
                    onClick={() => navigateToGame(gameEvent)}
                    className="sc-kAzzGY sc-kGXeez khGOWT snipcss-ZVoxR"
                  >
                    <div className="sc-fgfRvd sc-fyjhYU iVLMqL">
                      <div className="sc-bEjcJn bDjVzo">
                        <div name="football" className="sc-bdVaJa dlYXPt">
                          <img
                            src="https://opt-v3-files.raksahb.com/static/snk/brand/12/icon/png/cricket.png?v=20200106"
                            alt="football"
                          />
                        </div>
                      </div>
                      <div className="sc-kXeGPI jILwiI">
                        <div className="sc-eKZiaR dPeoaT">
                          <div className="sc-iQtOjA becvOz">
                            <div className="sc-iybRtq dTEysJ">
                              {gameEvent.eventName?.split(" v ")[0]}
                            </div>
                          </div>
                          <div
                            style={{
                              color: "rgb(115, 79, 22)",
                            }}
                            className="sc-iQtOjA becvOz"
                          />
                          <div className="sc-iQtOjA becvOz">
                            <div className="sc-hzDEsm cwTOUD">
                              {gameEvent.eventName?.split(" v ")[1]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                      }}
                      className="sc-fgfRvd sc-eIHaNI kuBKyh"
                    >
                      <div className="sc-jeCdPy hcRiFD">
                        <button className="sc-fZwumE hqQHXW">
                          <div
                            name="star-red-inactive"
                            className="sc-bdVaJa dlYXPt"
                          >
                            <img
                              src="https://opt-v3-files.raksahb.com/static/snk/brand/12/icon/png/star-red-inactive.png?v=20200106"
                              alt="star-red-inactive"
                            />
                          </div>
                        </button>
                      </div>

                      <div
                        className="sc-iomxrj dPlIVo style-mkkhr"
                        id="style-mkkhr"
                      >
                        <div type="BACK" className="sc-cqCuEk  bfFvRZ">
                          <div type="BACK" className="sc-ipXKqB bpYEWE">
                            {gameEvent?.back1}
                          </div>
                          <div type="BACK" className="sc-hmXxxW enjlwj">
                            {gameEvent?.backSize1}
                          </div>
                        </div>
                        <div type="LAY" className="sc-cqCuEk chfdGi">
                          <div type="LAY" className="sc-ipXKqB bpYEWE">
                            {gameEvent?.lay1}
                          </div>
                          <div type="LAY" className="sc-hmXxxW enjlwj">
                            {gameEvent?.laySize1}
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc-iomxrj dPlIVo style-3jBpb"
                        id="style-3jBpb"
                      >
                        <div type="BACK" className="sc-cqCuEk bfFvRZ">
                          <div type="BACK" className="sc-ipXKqB bpYEWE">
                            {gameEvent?.back11}
                          </div>
                          <div type="BACK" className="sc-hmXxxW enjlwj">
                            {gameEvent?.backSize11}
                          </div>
                        </div>
                        <div type="LAY" className="sc-cqCuEk chfdGi">
                          <div type="LAY" className="sc-ipXKqB bpYEWE">
                            {gameEvent?.lay11}
                          </div>
                          <div type="LAY" className="sc-hmXxxW enjlwj">
                            {gameEvent?.laySize11}
                          </div>
                        </div>
                      </div>
                      <div
                        className="sc-iomxrj dPlIVo style-9Q69V"
                        id="style-9Q69V"
                      >
                        <div type="BACK" className="sc-cqCuEk bfFvRZ">
                          <div type="BACK" className="sc-ipXKqB bpYEWE">
                            {gameEvent?.back12}
                          </div>
                          <div type="BACK" className="sc-hmXxxW enjlwj">
                            {gameEvent?.backSize12}
                          </div>
                        </div>
                        <div type="LAY" className="sc-cqCuEk chfdGi">
                          <div type="LAY" className="sc-ipXKqB bpYEWE">
                            {gameEvent?.lay12}
                          </div>
                          <div type="LAY" className="sc-hmXxxW enjlwj">
                            {gameEvent?.laySize12}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {!isUpcoming && (
                <div>
                  {cricketData
                    ?.filter((item) => !item?.inPlay)
                    ?.map((gameEvent) => (
                      <div
                        onClick={() => navigateToGame(gameEvent)}
                        className="sc-kAzzGY sc-kGXeez khGOWT snipcss-ZVoxR"
                      >
                        <div className="sc-fgfRvd sc-fyjhYU iVLMqL">
                          <div className="sc-bEjcJn bDjVzo">
                            <div name="football" className="sc-bdVaJa dlYXPt">
                              <img
                                src="https://opt-v3-files.raksahb.com/static/snk/brand/12/icon/png/cricket.png?v=20200106"
                                alt="football"
                              />
                            </div>
                          </div>
                          <div className="sc-kXeGPI jILwiI">
                            <div className="sc-eKZiaR dPeoaT">
                              <div className="sc-iQtOjA becvOz">
                                <div className="sc-iybRtq dTEysJ">
                                  {gameEvent.eventName?.split(" v ")[0]}
                                </div>
                              </div>
                              <div
                                style={{
                                  color: "rgb(115, 79, 22)",
                                }}
                                className="sc-iQtOjA becvOz"
                              >
                                {moment(
                                  gameEvent.eventTime
                                    ? gameEvent.eventTime
                                    : gameEvent.eventDate
                                ).format("DD MMM ")}
                                <br />
                                {moment(
                                  gameEvent.eventTime
                                    ? gameEvent.eventTime
                                    : gameEvent.eventDate
                                ).format("hh:mm A")}
                              </div>
                              <div className="sc-iQtOjA becvOz">
                                <div className="sc-hzDEsm cwTOUD">
                                  {gameEvent.eventName?.split(" v ")[1]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            width: "100%",
                          }}
                          className="sc-fgfRvd sc-eIHaNI kuBKyh"
                        >
                          <div className="sc-jeCdPy hcRiFD">
                            <button className="sc-fZwumE hqQHXW">
                              <div
                                name="star-red-inactive"
                                className="sc-bdVaJa dlYXPt"
                              >
                                <img
                                  src="https://opt-v3-files.raksahb.com/static/snk/brand/12/icon/png/star-red-inactive.png?v=20200106"
                                  alt="star-red-inactive"
                                />
                              </div>
                            </button>
                          </div>

                          <div
                            className="sc-iomxrj dPlIVo style-mkkhr"
                            id="style-mkkhr"
                          >
                            <div type="BACK" className="sc-cqCuEk geCOmX">
                              <div type="BACK" className="sc-ipXKqB bpYEWE">
                                {gameEvent?.back1}
                              </div>
                              <div type="BACK" className="sc-hmXxxW enjlwj">
                                {gameEvent?.backSize1}
                              </div>
                            </div>
                            <div type="LAY" className="sc-cqCuEk ebhzAF">
                              <div type="LAY" className="sc-ipXKqB bpYEWE">
                                {gameEvent?.lay1}
                              </div>
                              <div type="LAY" className="sc-hmXxxW enjlwj">
                                {gameEvent?.laySize1}
                              </div>
                            </div>
                          </div>
                          <div
                            className="sc-iomxrj dPlIVo style-3jBpb"
                            id="style-3jBpb"
                          >
                            <div type="BACK" className="sc-cqCuEk geCOmX">
                              <div type="BACK" className="sc-ipXKqB bpYEWE">
                                {gameEvent?.back11}
                              </div>
                              <div type="BACK" className="sc-hmXxxW enjlwj">
                                {gameEvent?.backSize11}
                              </div>
                            </div>
                            <div type="LAY" className="sc-cqCuEk ebhzAF">
                              <div type="LAY" className="sc-ipXKqB bpYEWE">
                                {gameEvent?.lay11}
                              </div>
                              <div type="LAY" className="sc-hmXxxW enjlwj">
                                {gameEvent?.laySize11}
                              </div>
                            </div>
                          </div>
                          <div
                            className="sc-iomxrj dPlIVo style-9Q69V"
                            id="style-9Q69V"
                          >
                            <div type="BACK" className="sc-cqCuEk geCOmX">
                              <div type="BACK" className="sc-ipXKqB bpYEWE">
                                {gameEvent?.back12}
                              </div>
                              <div type="BACK" className="sc-hmXxxW enjlwj">
                                {gameEvent?.backSize12}
                              </div>
                            </div>
                            <div type="LAY" className="sc-cqCuEk ebhzAF">
                              <div type="LAY" className="sc-ipXKqB bpYEWE">
                                {gameEvent?.lay12}
                              </div>
                              <div type="LAY" className="sc-hmXxxW enjlwj">
                                {gameEvent?.laySize12}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          ) : null}

          {selectedGame == "casino" ? (
            <>
              <div
                className="container-fluid"
                style={{ padding: "20px 27px" }}
              >
                <div className="row">
                  {/*  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/ander-bahar">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/ab20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Andar Bahar
                        </p>
                      </div>
                    </Link>
                  </div> */}

                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/live-teen20">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="/assets/images/teen20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Teen Patti T20
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/lucky7">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="/assets/images/lucky7.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Lucky 7A
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/dragon-tiger">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="/assets/images/dt20.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Dragon Tiger T20
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="/casino/ander-bahar">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "var(--secondary)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Andar Bahar
                        </p>
                      </div>
                    </Link>
                  </div>
                  {/* <div
                    className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                    style={{
                      marginBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <Link to="#">
                      <div
                        className="hover ehover4"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="img-responsive"
                          src="assets/images/amar.jpeg"
                          alt="king"
                          style={{ width: "100%" }}
                        />
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            background:
                              "linear-gradient(45deg, #720c48, #f51249)",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Amar Akbar Anthony
                        </p>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </>
          ) : null}
          {/* {selectedGame == "matka" ? <MatkaDashboard /> : null} */}
        </main>
      </div>
    </>
  );
};

export default Matches;
