import React from "react";

export default function TandC() {
  return (
    <app-main _nghost-ng-c3615156334="">
      <div
        _ngcontent-ng-c3615156334=""
        className="dashboard-wrapper inner_wrapper"
      >
        <div _ngcontent-ng-c3615156334="">
          <div
            style={{
              marginTop: "-5px",
              paddingBottom: "100px",
            }}
            _ngcontent-ng-c3615156334=""
            className="details_background"
          >
            <h4 _ngcontent-ng-c3615156334="">Terms &amp; Conditions</h4>
            <div _ngcontent-ng-c3615156334="" className="tabs_block_cms">
              <div
                _ngcontent-ng-c3615156334=""
                id="pills-tabContent"
                className="tab-content"
              >
                <div
                  _ngcontent-ng-c3615156334=""
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex={0}
                  className="tab-pane fade"
                >
                  <div
                    _ngcontent-ng-c3615156334=""
                    className="tab_inner_details"
                  >
                    <p _ngcontent-ng-c3615156334="">
                      1. Change your password after login.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      2. If the game is canceled or tied, all the deals of the
                      match will be considered canceled and the Coins will be
                      more or less according to the session that has been
                      completed.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      3. During the match Deal only by seeing and
                      understanding. Any transaction entered into will not be
                      deleted or replaced. You will be responsible for all
                      transactions.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      4. Match or Session Quote Wrong Any matches or sessions
                      that have been traded on the go will be automatically
                      deleted.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      5. Coins will be more or less according to the session
                      which will be completed in the match and all the
                      sessions will be canceled according to the session which
                      is not completed.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      6. Match I Over Come having But advance session fancy
                      will get canceled.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      7. Overs less in the match having But if the same team
                      comes to play again, the player's runs and partnership
                      in that running will be declared. if over less If the
                      other team comes to play, then the player runs in the
                      running, the partnership will be canceled
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      8. If the batsman is injured, he scores 34 runs as a
                      result of which 34 runs are awarded.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      9. innings in test On being declared, the over or ball
                      count of the other team will be done to declare the
                      session which is in running.
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      10. The decision of the company to declare and cancel
                      the session will be final. In such a situation, any kind
                      of debate will not be valid.{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      11. It is an offense for anyone under the age of 18 to
                      open an account or gamble on shakti11.com. Play at your
                      own risk{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      12. Four, sixes, wide, wicket, extra run, total run,
                      highest over and top batsman,maiden
                      over,caught-out,no-ball,run-out,fifty and century are
                      valid only match has been completed in case due to rain
                      over has been reduced all other fancy will be deleted{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      Note: Transactions made will be valid only in case of
                      any failure or closure of the server or website. In such
                      a case, any dispute will not be valid.
                    </p>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c3615156334=""
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex={0}
                  className="tab-pane fade show active"
                >
                  <div
                    _ngcontent-ng-c3615156334=""
                    className="tab_inner_details"
                  >
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      1. लॉगिन करने के बाद सभी पैनल हैंडलर अपना पासवार्ड चेंज
                      कर लेवे। किसी भी तरह के पासवर्ड कॉम्प्रोमाइज और उस वजह
                      से होने वाले डिफॉल्ट के लिए हम जिम्मेदार नहीं होंगे।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      2. डायमंड के न्यू रूल्स के मुताबिक पेनल्टी रनों के हिसाब
                      से रनिंग गेम में ही भाव को अपडेट कर दिया जाता है तो उसके
                      लिए पेनल्टी रनों को अलग से काउंट नहीं किया जाएगा सभी
                      वेलिड माने जाएंगे। उसके बाद अगर कोई गलत भाव का सौदा
                      मिलता है तो कंपनी एक्सपर्ट टीम उसको हटाने का अधिकार
                      सुरक्षित रखती है रनिंग मैच पीरियड में ओर फिनिश होने के
                      बाद भी।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      3. सभी रेट्स एंड भाव को समझने के बाद ही बेट्स लगाए ।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      4. किसी भी टैक्निकल इश्यू की वजह से ID बंद होने पर किए
                      गए सभी सौदे/ बेट्स मान्य किए जाएंगे , ऐसी अनावश्यक
                      समस्या के लिए हमें खेद है लेकिन हम उसके वजह से होने वाले
                      आपके मुनाफे और घाटे के लिए उत्तरदाई नहीं है।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      5. कोई भी मैच अगर किसी वजह से नो रिजल्ट या रद्द होता है
                      तो केवल कंप्लीट फैंसी आइटम्स को ही डिक्लेयर किया जाएगा।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      6. अगर किसी भी मैच का किसी भी कंडीशन में रिजल्ट घोषित
                      होता है तो रनिंग फैंसी आइटम्स सभी लास्ट स्कोरकार्ड के
                      हिसाब से डिक्लेयर किए जाएंगे।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      7. प्वाइंट नंबर 5 और 6 के अलावा अगर मैच में किसी भी एक
                      इनिंग्स के कंप्लीट होने के बाद अगर मैच नो रिजल्ट या रद्द
                      होता है तो उस कंडीशन में रनिंग सभी फैंसी को डिक्लेयर
                      किया जाएगा (50% गेम प्ले होना अनिवार्य) ।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      8. सुपरओवर जैसा कोई भी फैंसी एंड भाव मान्य नहीं होगा ।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      9. आगे से आने वाला डेटा थर्ड पार्टी फीड सिस्टम है उसमें
                      स्कोरकार्ड , टीवी इनका टाइम डिले या गलत हो सकता है ,
                      किसी भी गलत भाव के चलने पे गेम फिनिश के बाद भी बेट्स को
                      गलत पाए जाने पे डिलीट या अमान्य करने का अधिकार कंपनी
                      सुरक्षित रखती है।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      10. हमारे कंपनी के द्वारा किसी भी तरह की चीटिंग होने और
                      धोखाधड़ी होने के कोई भी चांस नहीं है , हम ईमानदारी से और
                      आपके बेहतर अनुभव के लिए सर्विस प्रोवाइड करने के लिए
                      बाध्य है। ये काम हम और पूरी shakti11 टीम बहुत पारदर्शिता
                      और ईमानदारी से करती आई है और आपका हमारे प्रति इसी भरोसे
                      और प्यार के साथ हमें इसको बेहतर बनाने की शक्ति मिलती
                      रहती है।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      11. हम फेसबुक , इंस्टाग्राम , व्हाट्सएप हेल्पलाइन तीनों
                      प्लेटफॉर्म्स पे आपके सुझाव और बेहतर सर्विस के लिए
                      अवेलेबल है ।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      12. किसी भी फैंसी आइटम की कभी भी कोई अपडेटेड फैंसी रनिंग
                      में ऐड होती है तो उस कंडीशन में फर्स्ट फैंसी को रद्द कर
                      दिया जाता है और अपडेट फैंसी को डिक्लेयर किया जाता है।{" "}
                    </p>
                    <p _ngcontent-ng-c3615156334="">
                      {" "}
                      नोट :- सभी रूल्स के अकॉर्डिंग ही आगे के सभी रिजल्ट
                      डिक्लेयर किए जाएंगे क्रिकेट में रोज बहुत सारे नए आइटम्स
                      ऐड हो रहे है है उसके लिए आप कंपनी नंबर पे जानकारी लेवे
                      या भाव और बेट्स को समझने के बाद ही प्लेस करे , कंडीशनल
                      अगर कोई नया रूल अपडेट करने की जरूरत महसूस होने पे ऐड
                      किया जाएगा ।।{" "}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <button
                        _ngcontent-ng-c3615156334=""
                        type="button"
                        onClick={() => {
                          window.location.href = "/dashboard";
                        }}
                        className="btn btn-primary"
                      >
                        Continue
                      </button>
                    </div>
                    <p
                      style={{
                        color: " rgb(111, 109, 230)",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      _ngcontent-ng-c3615156334=""
                    >
                      {" "}
                      Thank you 🙏🙏 Team shakti11 🙏🙏
                    </p>
                    <div
                      _ngcontent-ng-c3615156334=""
                      className="continue_btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-main>
  );
}
